@import '../../../stylesheets/variables.postcss';

.ServicesForm {
  textarea {
    height: 248px;
    resize: vertical;
  }

  button {
    float: right;
  }

  :global(.u-grid-col) {
    margin-bottom: 0;
  }

  :global(.Fieldset) {
    margin: 20px 0 !important;
  }

  :global(.FormRow) {
    padding: 0 !important;
  }
}

.Required {
  font-weight: 600;
  color: $otherGrey;
  font-size: 0.625rem;
  margin-right: 1rem;
  margin-top: -1.25rem;
  position: absolute;
  right: 0;
  top: 6.25rem;
}